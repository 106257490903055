import React from 'react';
import styled from 'styled-components';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { graphql } from 'gatsby';
import {
  SubHeading, Para, Panel, Container, Heading,
} from '../../elements';
import Back from '../../components/Back';
import Content from '../../components/Content';
import IconList from '../../components/IconList';
import AppContainer from '../../components/AppContainer';

const Text = styled(Para)`
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  /* color: ${props => props.theme.light}; */
`;

const Grid = styled.div`
  display: grid;
`;
const Section = styled.section`
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px dashed #6289d9;
`;
const Chart = styled.div`
  .highcharts-container {
    width: 100% !important;
    > svg {
      width: 100%;
    }
  }
`;
const NextStepContainer = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  background-color: ${props => props.theme.light};
`

const ContentLeft = ({ page }) => (
  <Grid>
    <Panel color="dark">
      <Back to="/overview" color="light" />
      <Heading ariaRole="heading" light>
        {page.heading}
      </Heading>
      {page.sections.filter(sec => sec.title !== 'Community').map(section => (
        <Section key={section.id}>
          <SubHeading ariaRole="heading">{section.heading}</SubHeading>
          {section.list && (
            <IconList bullets={!section.list.listItem[0].icon} list={section.list} />
          )}
          {section.content && (
            <Content
              className="html-inline"
              content={section.content.childContentfulRichText.html}
            />
          )}
        </Section>
      ))}
    </Panel>
  </Grid>
);

const ContentRight = ({ page }) => {
  return page.sections.filter(sec => sec.title === 'Community').map(section => (
    <Panel color="white" key={section.id}>
      <SubHeading>{section.heading}</SubHeading>

      {section.tables
        && section.tables.map((table) => {
          const ChartData = table.childContentfulTableTableJsonNode
            ? JSON.parse(table.childContentfulTableTableJsonNode.internal.content)
            : {};

          return (
            <React.Fragment key={table.id}>
              {table.subHeading && <Text>{table.subHeading}</Text>}
              <Chart>
                <HighchartsReact highcharts={Highcharts} options={ChartData.data} />
              </Chart>
              {table.footer && (
                <Content
                  className="html-small"
                  content={table.footer.childContentfulRichText.html}
                />
              )}
            </React.Fragment>
          );
        })}
        <NextStepContainer>
        {section.content && section.content.childContentfulRichText.html && (
          <Content content={section.content.childContentfulRichText.html} />
          )}
        </NextStepContainer>
    </Panel>
  ));
};

const Readership = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Container id="content">
      <ContentLeft page={page} />
      <ContentRight page={page} />
    </Container>
  </AppContainer>
);

export const readershipPageQuery = graphql`
         query readershipPageQuery($id: String!) {
           contentfulPage(id: { eq: $id }) {
             title
             heading
             sections {
               id
               title
               heading
               subHeading
               content {
                 childContentfulRichText {
                   html
                 }
               }
               list {
                 title
                 listItem {
                   text {
                     text
                   }
                   icon
                 }
               }
               tables {
                 id
                 heading
                 subHeading
                 childContentfulTableTableJsonNode {
                   internal {
                     content
                   }
                 }
               }
             }
           }
         }
       `;
export default Readership;
