import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '../../elements';
import { Location } from '@reach/router';
const BackLink = styled.button`
  @media print {
   display: none; 
  }
  margin-bottom: 1rem;
  display: inline-block;
  color: ${props => (props.color ? props.theme[props.color] : props.theme.blue)};
`;
class Back extends Component {
  goBack = (navigate, location) => {

    const { to } = this.props;
    to ? navigate(to) : window.history.back();
  };

  render() {
    const { color } = this.props;
    return (
      <Location>
        {({ location, history, navigate }) => (
          <BackLink
            color={color}
            className="text-link"
            tabIndex={0}
            title="Go back"
            onClick={() => this.goBack(navigate, location)}
          >
            <Icon>arrow_back</Icon> Back
          </BackLink>
        )}
      </Location>
    );
  }
}

export default Back;
