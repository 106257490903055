import React from 'react';
import PropTypes from 'prop-types';
import { UnorderedList, Ul, Icon } from '../../elements';
import Content from '../Content';

const IconList = ({ list, bullets }) => (bullets ? (
  <Ul>
    {list.listItem.map((item, i) => (
      <li key={i}>{item.text.text}</li>
    ))}
  </Ul>
) : (
  <UnorderedList>
    {list.listItem.map((item, i) => (
      <li key={i}>
        <Icon>{item.icon}</Icon>
        <Content content={item.text.text} />
      </li>
    ))}
  </UnorderedList>
));

IconList.propTypes = {
  bullets: PropTypes.bool,
  list: PropTypes.shape({
    listItem: PropTypes.array,
  }),
};
export default IconList;
